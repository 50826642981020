@import "~sass-bem";

.re-xlWidget:not(.re-xlWidget--editMode):not(:focus-within),
.re-xlWidget--disabled {
  .re-xlWidget__buttonContainer {
    max-width: 0px;
  }
}

@include block("re-xlWidget") {
  @include element("buttonContainer") {
    max-width: 100%;
    transition: max-width 0.5s;
    margin-left: 10px;

    > div {
      overflow: hidden;
    }
  }

  @include element("textArea") {
    position: relative;
    transition: margin-bottom 0.5s;
    z-index: 1;

    @include element("buttonContainer") {
      max-width: none !important; // i'm so sorry
      transition: bottom 0.5s;
      bottom: 0px;
      right: 0;
      position: absolute;
      z-index: -1;
    }
  }

  @include element("textArea") {
    @include while("editMode") {
      margin-bottom: 50px;
      @include element("buttonContainer") {
        max-width: initial;
        bottom: -50px;
      }
    }
  }

  label {
    color: revo-color("typo");
    display: block;
    font-size: 1em;
    font-weight: 600;
    line-height: $base-line-height * 1.25;
    margin-bottom: $space;
  }

  .re-input:focus-within {
    z-index: 10000;
  }
}

@include block("re-xlWidgetView") {
  label {
    color: revo-color("typo");
    display: block;
    font-size: 1em;
    font-weight: 600;
    line-height: $base-line-height * 1.25;
  }
}

.re-xlWidgetView {
  white-space: pre-wrap;
}
