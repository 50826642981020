div.appHeaderMultiDashboardSelect {
  position: relative;
  top: -6px;
  height: 11.75px;
}

div.appHeaderMultiDashboardSelectRedesign {
  position: relative;
  align-self: center;
  padding: 0 40px;
}

.single-line-with-ellipsis {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
}

.dashboard-widget {
  border-radius: 5px;
  background-color: white;
  height: 100%;
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 30%), 0 0 0 1px rgb(0 0 0 / 4%);
  cursor: default;
  overflow: hidden;
  user-select: auto;

  &.add-section {
    background-color: transparent;
    box-shadow: none;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &.edit-mode.edit-mode-resize {
    cursor: move;
    user-select: none;
  }

  .dashboard-widget-header {
    height: 30px;
    background-color: inherit;
    color: black;
    padding: 5px 4px 0 10px;
    cursor: auto;
    border-radius: 0;

    &:not(.edit-mode) {
      cursor: pointer;
    }

    &.edit-mode {
      cursor: move;
      user-select: none;
      pointer-events: none;
    }

    b,
    p,
    span {
      @extend .single-line-with-ellipsis;
      font-weight: bold;
    }

    &.dashboard-widget-header-floating {
      position: relative;
      z-index: 3;
      background-color: transparent;
      height: 30px;

      span {
        white-space: nowrap;
      }
    }

    .widget-edit-button {
      position: absolute;
      cursor: pointer;
      pointer-events: auto;
      background-image: radial-gradient(circle at 45% 50%, rgba(255, 255, 255, 1) 20%, rgba(255, 255, 255, 0.3) 90%);

      &.remove-button {
        right: 5px;
        &.shifted {
          right: 30px !important;
        }
      }
      &.config-button {
        right: 5px;
      }
    }

    .widget-config-cog {
      //top: 5px;
      position: absolute;
      cursor: pointer;
      pointer-events: auto;
      right: 5px;
      //padding-left: 5px;
      background-image: radial-gradient(circle at 45% 50%, rgba(255, 255, 255, 1) 20%, rgba(255, 255, 255, 0.3) 90%);
    }
  }

  .dashboard-widget-content {
    height: 100%;
    overflow: hidden;

    &.edit-mode {
      overflow: hidden;
      pointer-events: none;
    }
  }

  &.dashboard-widget-floating {
    > div:nth-child(2) {
      // widget content
      height: 100%;
      position: relative;
      z-index: 0;
      top: -30px;
      width: 100%;
    }
  }

  .canvas-container {
    height: 100%;
    justify-self: center;
    width: 99%;
  }

  .dashboard-widget-assessment-dashboard-nearest-accessibility-widget,
  .dashboard-widget-macro-map-widget {
    // Open Layers map is not pixel-perfect and it's not rendering tiles on a boarder of the widget. We stretch it a bit to cover the whole widget.
    > div:nth-child(2) {
      // widget content
      width: 101%;
      height: 101%;
    }
  }
}

.dashboard-widget-dashboard-widget-scores {
  .dashboard-widget-content {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='1' height='100' viewBox='0 0 1 100'%3E%3Cline x1='0' y1='0' x2='0' y2='100' stroke='rgb(221,221,221)' /%3E%3C/svg%3E");
    background-repeat: repeat-y;
    background-position: center;
  }

  .dashboard-widget-header {
    background-color: white;
  }

  .background-score-under50 {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20'%3E%3Ccircle cx='10' cy='10' r='10' fill='rgb(220,220,245)' /%3E%3C/svg%3E");
    background-repeat: no-repeat;
  }

  .background-score-over50 {
    background-repeat: no-repeat;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20'%3E%3Ccircle cx='10' cy='10' r='10' fill='rgb(220,220,245)' /%3E%3C/svg%3E");
  }
}

/**
It's used to fix the flickering of the dashboard when the overlay is open. Scrollbar is hidden by default.
 */
body.bp4-overlay-open:has(.appHeaderMultiDashboardSelect) {
  overflow: inherit;
}
