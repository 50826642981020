.re-footer {
  color: revo-color("typo", "dark");
  text-align: center;
  margin: 20px 20px;

  span {
    .re-tooltip {
      display: inline;
    }

    padding: 10px;
  }

  .version {
    color: revo-color("primary");
  }

  .feedback {
    color: revo-color("primary");
    padding: 0;
  }
}
