@function re-flatten-map($map, $prefix) {
  $result: ();
  @each $key, $value in $map {
    $newprefix: #{$prefix}-#{$key};
    @if type-of($value) == "map" {
      $result: map-merge($result, re-flatten-map($value, $newprefix));
    } @else {
      $flattened-key: #{$prefix}-#{$key};
      $result: map-merge(
        $result,
        (
          $flattened-key: $value,
        )
      );
    }
  }
  @return $result;
}

@function revo-theme-register($theme) {
  $theme-variables: ();
  @each $key, $value in re-flatten-map($theme, "") {
    $theme-variables: append($theme-variables, --revo#{$key});
  }
  @return $theme-variables;
}

$revo-theme-variables: revo-theme-register(
  (
    color: $revo-colors,
  )
);

@mixin revo-theme($theme) {
  @each $key, $value in re-flatten-map($theme, "") {
    --revo#{$key}: #{$value};
  }
}

@function revo-var-exists($name) {
  @if index($revo-theme-variables, $name) {
    @return true;
  } @else {
    @return false;
  }
}

@function revo-var($name) {
  @if not(revo-var-exists($name)) {
    @error "theme var #{$name} does not exist";
  }
  @return var($name);
}

@mixin revo-theming {
  body {
    @include revo-theme(
      (
        color: $revo-colors,
      )
    );
  }
  body.re-theme-pwc {
    @include revo-theme(
      (
        color: $pwc-revo-colors,
      )
    );
  }
}

@function revo-color($color, $variant: "default") {
  $result: "revo-color-";

  @if not map-has-key($revo-colors, $color) {
    @error "color parameter #{$color} does not exist!";
  }

  $key: map-get($revo-colors, $color);

  @if type-of($key) == map {
    @if not map-has-key($key, $variant) {
      @error "parameter #{$variant} does not exist on color #{$color}!";
    }

    $result: --revo-color-#{$color}-#{$variant};

    @return revo-var($result);
  } @else if not map_has_key($revo-colors, $color) {
    @error "color parameter #{$color} does not exist!";
  }

  $result: --revo-color-#{$color};
  @return revo-var($result);
}

@mixin revo-color-iter {
  @each $color, $mapVal1 in map-merge($revo-ui-state-colors, $revo-secondary-colors) {
    @content ($color);
  }
}
