// Helper Classes for padding & margin

@each $spacingSize, $value in $spacingSizes {
  .re-space-p-x--#{$spacingSize} {
    padding-left: #{$value};
    padding-right: #{$value};
  }

  .re-space-p-y--#{$spacingSize} {
    padding-top: #{$value};
    padding-bottom: #{$value};
  }

  .mui-container.re-space-between--#{$spacingSize},
  .mui-container-fluid.re-space-between--#{$spacingSize} {
    > :not(:last-child) {
      margin-bottom: #{$value};
    }
  }

  .re-flex__container--col.re-space-between--#{$spacingSize} {
    > :not(:last-child) {
      margin-bottom: #{$value};
    }
  }
  .re-flex__container--row.re-space-between--#{$spacingSize} {
    > :not(:last-child) {
      margin-right: #{$value};
    }

    > .re-flex__col:not(:last-child) {
      margin-right: 0;
      padding-right: #{$value};
    }
  }
}
