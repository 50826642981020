@import "./core/core";
@import "~sass-bem";

////////////////////////////////////////////////////////////////////
///////// VARS & MIXINS ////////////////////////////////////////////
////////////////////////////////////////////////////////////////////

// ICONBAR WIDTHS
$iconbar-width-mobile: 52px;
$iconbar-width-tablet: 52px;
$iconbar-width-desktop: 52px;

// FLYOUTMENU WIDTH
$flyout-width-mobile: 100%;
$flyout-width-tablet: 320px;
$flyout-width-desktop: 320px;

$css-icon-var: var(--icon-size);
$css-flyout-var: var(--flyout-size);

// ICONBAR WIDTHS REDESIGN
$iconbar-width-mobile-redesign: 60px;
$iconbar-width-tablet-redesign: 60px;
$iconbar-width-desktop-redesign: 60px;

// FLYOUTMENU WIDTH REDESIGN
$flyout-width-mobile-redesign: 100%;
$flyout-width-tablet-redesign: 271px;
$flyout-width-desktop-redesign: 271px;

$css-icon-var-redesign: var(--icon-size-redesign);
$css-flyout-var-redesign: var(--flyout-size-redesign);

@mixin menuSlideAnimation($attribute) {
  transition: $attribute 0.3s ease-in-out;
}

@mixin menuSlideAnimations($attributes) {
  $transitionList: "";
  @for $i from 1 through length($attributes) {
    $attr: nth($attributes, $i);
    @if $i > 1 {
      $transitionList: $transitionList + ", ";
    }
    $transitionList: $transitionList + "#{$attr} .3s ease-in-out";
  }

  transition: #{$transitionList};
}

@import "./revoAppDrawer";

body {
  background-color: revo-color("white");
}

.menu {
  @include element("separator") {
    height: 24px;
    width: 1px;
    border-right: 1px solid revo-color("typo", "lighter");
  }
  @include element("content") {
    height: 72px;
    background-color: white;
    margin-left: 72px;
  }

  @include element("logo") {
    height: 72px;
    width: 97px;

    > svg {
      margin-left: 25px;
      margin-top: 15px;
    }
  }

  @include element("mainbar") {
    width: 72px;
    position: fixed;
    left: 0;
    top: 0;
    height: 100vh;
    background-color: revo-color("primary", "default");
  }
}
////////////////////////////////////////////////////////////////////
///////// MENU STYLES //////////////////////////////////////////////
////////////////////////////////////////////////////////////////////

@include block("revoMenu") {
  @include element("group") {
    display: grid;
    flex-direction: column;
  }

  @include element("menuWrapper") {
    display: grid;
    grid-template-columns: fit-content(100%) 1fr fit-content(100%);

    @include untilTablet {
      --icon-size: #{$iconbar-width-tablet};
      --flyout-size: #{$flyout-width-tablet};
      --icon-size-redesign: #{$iconbar-width-tablet-redesign};
      --flyout-size-redesign: #{$flyout-width-tablet-redesign};
    }

    @include whileTablet {
      --icon-size: #{$iconbar-width-tablet};
      --flyout-size: #{$flyout-width-tablet};
      --icon-size-redesign: #{$iconbar-width-tablet-redesign};
      --flyout-size-redesign: #{$flyout-width-tablet-redesign};
    }

    @include whileDesktopSmall {
      --icon-size: #{$iconbar-width-tablet};
      --flyout-size: #{$flyout-width-tablet};
      --icon-size-redesign: #{$iconbar-width-tablet-redesign};
      --flyout-size-redesign: #{$flyout-width-tablet-redesign};
    }

    @include fromDesktopWide {
      --icon-size: #{$iconbar-width-desktop};
      --flyout-size: #{$flyout-width-desktop};
      --icon-size-redesign: #{$iconbar-width-desktop-redesign};
      --flyout-size-redesign: #{$flyout-width-desktop-redesign};
    }

    a {
      text-decoration: none;
    }
  }

  @include element("mobileMenu") {
    background-color: revo-color("white");
    height: $iconbar-width-mobile;
    overflow: hidden;
    width: 100%;
    z-index: 11150;

    .revoMainMenu__wrapper--expanded & {
      box-shadow: none;
    }

    @include whileMobile {
      box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.3);
      position: fixed;
      top: 0;
    }

    @include whileTabletPortrait {
      border-bottom: 1px solid revo-color("border");
      grid-column: span 2;
      height: $iconbar-width-tablet;
      position: fixed;
      top: 0;
    }

    @include fromTabletLandscape {
      display: none;
    }

    @include modifier("expanded") {
      border-bottom: 1px solid revo-color("border");
      box-shadow: none;
    }
  }

  @include element("contentWrapper") {
    min-height: 20px;
    height: 100vh;

    .scrollLock--enabled & {
      overflow: hidden;
    }
  }
}

.revoMainMenuRedesign {
  &.revoMainMenu__wrapper--overlay {
    .revoMainMenu__backgroundWrapper {
      width: $css-flyout-var-redesign;
      height: 100%;
    }
  }

  .revoMainMenu__menuItemModule {
    overflow: hidden;
  }

  .revoMenuItem__button {
    button {
      i {
        margin-right: 2px;
      }
    }
  }
}

@include block("revoMainMenu") {
  @include element("appTitleWrapper") {
    .revoMenuItem__button {
      button {
        background-color: revo-color("primary", "default");
        height: $css-icon-var;
      }
    }
  }

  @include element("menuWrap") {
    width: 52px;
    height: 100%;
    display: flex;
    flex-direction: column;
    flex-grow: 0;
    flex-wrap: nowrap;
    flex-basis: auto;
    flex-shrink: 0;
    overflow: hidden;
  }

  @include element("mobileAppTitle") {
    align-items: center;
    color: revo-color("primary", "dark");
    display: flex;
    font-size: 18px;
    font-weight: bold;
    padding: 0 20px;
  }

  @include element("mobileSidebarToggle") {
    button {
      background-color: revo-color("white");
      color: revo-color("primary");
      border: none;
    }

    @include whileTablet {
      border-left: 1px solid revo-color("border");
    }

    @include modifier("open") {
      @include untilTablet {
        button {
          background-color: revo-color("background", "lighter");
        }
      }
      @include whileTablet {
        box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.3);
      }
    }
  }

  @include element("wrapper") {
    display: grid;
    width: $css-icon-var;
    height: 100%;
    z-index: 10000;

    &.impersonate {
      @include element(("blankSpace", "footerLogoWrap")) {
        background-color: red;
      }
      .revoMenuItem__button,
      button {
        background-color: red;
      }
      .revoMenuItem__button {
        button {
          background-color: red;
        }
      }
    }

    @include whileTabletPortrait {
      @include modifier("expanded") {
        display: initial;
      }

      @include modifier("overlay") {
        display: initial;
      }
    }

    @include fromDesktop {
      @include menuSlideAnimation(width);
    }

    @include modifier("expanded") {
      position: fixed;

      pointer-events: auto;
      transition-duration: 0ms;
      transition-delay: 0ms;

      @include element("wrapAppsList") {
        transition: width 0ms ease-in-out 0ms;
      }
      @include element("backgroundWrapper") {
        transition: width 0ms ease-in-out 0ms;
      }

      @include element("menuWrap") {
        width: auto;
        overflow: initial;
      }

      @include fromTablet {
        position: initial;
      }
    }

    @include modifier("overlay") {
      position: fixed;

      @include element("wrapAppsList") {
        transition: width 0ms ease-in-out 0ms;
      }

      @include element("menuWrap") {
        width: auto;
        overflow: initial;
      }

      @include fromTablet {
        position: initial;
      }
    }

    @include fromDesktop {
      @include modifier("expanded") {
        width: $flyout-width-desktop;
      }
    }
  }

  @include element("backgroundWrapper") {
    grid-column-start: 1;
    grid-row-start: 1;
    display: grid;

    height: 100%;

    z-index: 0;

    grid-template-columns: $css-icon-var 1fr;
    position: fixed;
    width: $css-icon-var;

    .revoMainMenu__wrapper--expanded & {
      box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.3);
    }

    .revoMainMenu__wrapper--overlay:not(.revoMainMenuRedesign) & {
      box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.3);
    }

    .revoMainMenu__wrapper--expanded & {
      display: grid;
      width: 100vw;
      height: 100vh;
    }

    .revoMainMenu__wrapper--overlay & {
      display: grid;
      width: 100vw;
      height: 100vh;
    }

    .revoMainMenu__wrapper--expanded & {
      position: absolute;
    }

    .revoMainMenu__wrapper--overlay & {
      position: absolute;
    }

    @include menuSlideAnimation(width);

    .revoMainMenu__wrapper--expanded & {
      width: $css-flyout-var;
    }

    .revoMainMenu__wrapper--overlay & {
      width: $css-flyout-var;
    }
  }

  @include element("menu") {
    display: flex;
    flex-direction: column;
    grid-column-start: 1;
    grid-row-start: 1;
    height: 100vh;
    position: fixed;
    z-index: 1000;

    ul.popup-menu {
      position: absolute;
      bottom: 3px;
      left: calc(100% + 0px);
      min-width: 138px;
      background: #fff;
      display: none;
      margin: 0;
      list-style: none;
      border-radius: 3px;
      box-shadow: rgb(0 0 0 / 21%) 0 6px 32px 0;
      will-change: transform;
      padding: 8px;

      &.active {
        display: block;
      }

      &:after {
        content: "";
        width: 0;
        height: 0;
        position: absolute;
        bottom: 14px;
        left: -5px;
        border-width: 5px 5px 5px 0;
        border-style: solid;
        border-color: transparent #fff;
      }
      &:before {
        content: "";
        width: 0;
        height: 0;
        position: absolute;
        bottom: 14px;
        left: -7px;
        border-color: transparent rgba(0, 0, 0, 0.05);
        border-style: solid;
        border-width: 5px 5px 5px 0;
      }

      li {
        list-style: none;
        padding: 4px;
        font-size: 12px;
        line-height: 16px;
        color: revo-color("typo", "default");
        margin-bottom: 4px;
        &:hover {
          background-color: revo-color("background", "lighter");
          cursor: pointer;
        }
        a {
          padding: 0 16px;
          border: 0;
          display: block;
          border-radius: 4px;
          text-align: left;
          white-space: nowrap;
          outline: none;
          line-height: 24px;
          background-color: transparent;
          font-size: 14px;
          color: revo-color("primary", "default");

          &.redText {
            color: revo-color("negative", "default");
          }

          &:hover {
            cursor: pointer;
          }
        }
      }
    }

    @include whileTabletPortrait {
      .revoMainMenu__wrapper--expanded & {
        display: initial;
      }

      .revoMainMenu__wrapper--overlay & {
        display: initial;
      }
    }
  }

  @include element("itemsWrapper") {
    scrollbar-width: none;
    flex: 1 1 auto;
    display: block;
    flex-direction: column;

    &::-webkit-scrollbar {
      width: 0;
      background-color: transparent;
    }

    @include element("scrollerHelper") {
      position: fixed;

      width: var(--icon-size);
      text-align: center;
      z-index: 1000;
      user-select: none;
      transition: width 0s;

      @include modifier("top") {
        top: calc(var(--icon-size) + 1px);

        @include whileMobile {
          top: var(--icon-size);
        }

        background: linear-gradient(rgb(90, 85, 235) 60%, rgba(90, 85, 235, 0));
        & i {
          top: -4px;
        }
      }

      @include modifier("bottom") {
        bottom: var(--icon-size);
        background: linear-gradient(rgba(90, 85, 235, 0), rgb(90, 85, 235) 60%);
        & i {
          top: 4px;
        }
      }

      & i {
        color: revo-color("primary", "light");
        font-weight: bold;
        position: relative;
      }
    }
  }

  @include element("menuToggleWrapper") {
    flex: 0 0 auto;
    display: flex;
    align-items: flex-start;

    .revoMenuItem__text {
      border-bottom: 1px solid revo-color("border");
      font-size: 18px;
      font-weight: bold;
      color: revo-color("primary", "dark");
      height: $css-icon-var;
    }

    .revoMenuItem__button {
      z-index: 999;
      button {
        border-color: revo-color("primary", "dark");
        height: $css-icon-var;
        background-color: revo-color("primary", "dark");
      }

      border-bottom: 1px solid revo-color("border");
    }

    @include modifier("mobile") {
      display: flex;
      align-items: stretch;
      flex-direction: column;
      justify-content: flex-start;
    }
  }

  @include element("topBarLogo") {
    display: flex;
    flex-direction: column;
    flex: 0 0 auto;
    justify-content: center;
    margin-right: 12px;
  }

  @include element("wrapAppsList") {
    z-index: 10;
  }

  @include element("blankSpace") {
    width: 52px;
    display: block;
    padding: 16px;
    z-index: 999;
    flex: 0 0 auto;
    background-color: revo-color("primary", "default");
  }

  @include element("dropMenu") {
    @include menuSlideAnimation(transform);
    display: block;
    padding: 16px;
    width: 100%;
    overflow: hidden;

    transform: translateX(-100%);

    .revoMainMenu__wrapper--expanded & {
      transform: translateX(0);
      overflow: initial;
    }

    .revoMainMenu__wrapper--overlay & {
      transform: translateX(0);
      overflow: initial;
    }

    > div {
      width: 100%;
    }

    button {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }

    ul {
      width: 100%;

      li.divider {
        cursor: default;
      }

      li {
        padding: 4px !important;
        margin-bottom: 4px;
        height: 33px;

        &:last-child {
          margin-bottom: 0;
        }
        &:hover:not(.divider) {
          background-color: revo-color("background", "lighter");
          button {
            background-color: transparent !important;
          }
        }
      }
    }
  }

  @include element("menuItem") {
    display: inline-flex;
    flex: 0 0 auto;
    width: $css-flyout-var;
    position: relative;

    @include menuSlideAnimation(width);

    &:hover {
      button {
        background-color: revo-color("primary", "dark");
        border-color: revo-color("primary", "dark");
      }
      .revoMenuItem__text {
        background-color: revo-color("background", "lighter");
      }

      @include element("dropMenu") {
        button {
          background-color: revo-color("primary", "default");
        }
        ul {
          li {
            button {
              background-color: initial;
            }
          }
        }
      }
    }

    @include modifier("active") {
      button {
        background-color: revo-color("primary", "dark");
        border-color: revo-color("primary", "dark");
      }
      .revoMenuItem__text {
        background-color: revo-color("background", "lighter");

        span {
          color: revo-color("primary", "dark");
        }
      }
    }
  }

  @include element("menuItemModule") {
    display: inline-flex;
    flex: 0 0 auto;
    width: $css-flyout-var;
    @include menuSlideAnimation(width);

    &:hover {
      button {
        background-color: revo-color("primary", "dark");
        border-color: revo-color("primary", "dark");
      }
      .revoMenuItem__text {
        background-color: revo-color("background", "lighter");
        border-top: 2px solid white;
        border-bottom: 2px solid white;
      }
      @include element("dropMenu") {
        button {
          background-color: revo-color("primary", "default");
        }
        ul {
          li {
            button {
              background-color: initial;
            }
          }
        }
      }
    }

    @include modifier("active") {
      button {
        background-color: revo-color("primary", "dark");
        border-color: revo-color("primary", "dark");
      }
      .revoMenuItem__text {
        background-color: revo-color("background", "lighter");
        border-top: 2px solid white;
        border-bottom: 2px solid white;

        span {
          color: revo-color("primary", "dark");
        }
      }
    }
  }

  @include element("appTitleWrapper") {
    padding: 16px 8px;
  }
  @include element("footerLogo") {
    background-image: url(https://assets.21real.estate/21_logo_inverted.svg);
    background-size: cover;
    width: 36px;
    height: 15px;
    background-position: 0px -1px;
  }

  @include element("footerServiceWrap") {
    display: flex;
    align-items: center;
    height: 100%;
    &:hover {
      @include element("footerLogoWrap") {
        background-color: revo-color("primary", "dark");
      }
      @include element("footerService") {
        background-color: revo-color("background", "lighter");
      }
    }
  }

  @include element("footerWrapper") {
    display: flex;
    flex-direction: column;
  }

  @include element("footerUserWrap") {
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;

    @include element("footerLogoWrap") {
      justify-content: center;

      padding: 0 14px;
      i {
        color: revo-color("background", "default");
        cursor: default;
      }
    }

    &:hover {
      @include element("footerLogoWrap") {
        background-color: revo-color("primary", "dark");
      }
      @include element("footerService") {
        background-color: revo-color("background", "lighter");
      }
    }
  }

  @include element("footerLogoWrap") {
    padding: 0 8px;
    height: 52px;
    display: flex;
    align-items: center;
    background-color: revo-color("primary", "default");
    z-index: 50;
  }

  @include element("footerService") {
    @include menuSlideAnimation(transform);

    white-space: nowrap;
    transform: translateX(-100%);
    height: 100%;
    display: flex;
    align-items: center;
    width: 100%;

    > div {
      cursor: default;
    }

    &:after {
      content: " ";
      position: absolute;
      right: -11px;
      width: 10px;
      height: 100%;
      background: transparent;
    }

    .revoMainMenu__wrapper--expanded & {
      transform: translateX(0);
    }

    .revoMainMenu__wrapper--overlay & {
      transform: translateX(0);
    }

    &:hover {
      ul.popup-menu {
        display: block;
      }
    }
  }
}

@include block("revoMenuItem") {
  @include element("logo") {
    @include menuSlideAnimation(transform);
    display: flex;
    align-self: center;

    > img {
      height: auto;
      width: 100%;
    }

    @include whileMobile {
      height: 32px;
      width: 96px;
    }

    @include whileTablet {
      height: 40px;
      width: 120px;
    }

    @include fromDesktop {
      height: 48px;
      width: 144px;
    }

    padding: 0 20px;
    white-space: nowrap;
    z-index: -10;

    .revoMainMenu__wrapper--expanded & {
      transform: translateX(0);
    }

    .revoMainMenu__wrapper--overlay & {
      transform: translateX(0);
    }
  }

  @include element("logoTop") {
    width: 80px;
    position: relative;
    left: -10px;
    display: flex;
  }

  @include element("text__container") {
    display: flex;
    width: 100%;
  }

  @include element("text") {
    display: flex;
    align-items: center;

    color: revo-color("primary");

    padding: 0 20px;
    transform: translateX(-100%);
    white-space: nowrap;
    z-index: -10;

    @include untilTablet {
      width: 100%;
    }

    @include fromTablet {
      @include menuSlideAnimations((transform, width));
      transform: translateX(calc(-1 * #{$css-flyout-var} - #{$css-icon-var}));
      width: calc(#{$css-flyout-var} - #{$css-icon-var});
    }

    .revoMainMenu__wrapper--expanded & {
      transform: translateX(0);
    }

    .revoMainMenu__wrapper--overlay & {
      transform: translateX(0);
    }
  }

  @include element("button") {
    align-self: end;
    z-index: 99;
    pointer-events: auto;

    button {
      border-radius: 0;
      height: 52px;
      width: 52px;
      &:active,
      &:focus {
        outline: none;
      }
    }

    @include whileTabletPortrait {
      button {
        height: $iconbar-width-tablet;
        width: $iconbar-width-tablet;
      }
    }

    @include fromTabletLandscape {
      button {
        width: $css-icon-var;
      }
    }

    @include fromDesktop {
      button {
        width: $css-icon-var;
      }
    }
  }
}

.revoMainMenu__footerUserWrap .revoMainMenu__footerService {
  border-top: 1px solid revo-color("border", "default");
}

.revoMainMenu__footerUserWrap .revoMainMenu__footerLogoWrap {
  border-top: 1px solid revo-color("border", "default");
}

.revoMainMenu__footerUserWrap .revoMainMenu__footerService li.divider {
  cursor: default;
  background: none;
}

@include block("devHelper") {
  @include element("responsiveIndicator") {
    &:before {
      display: block;
      padding: 5px;
      font-size: 12px;
    }

    @include whileMobilePortrait {
      &:before {
        content: "Mobile Portrait";
        background-color: hsl(90, 50%, 50%);
      }
    }
    @include whileMobileLandscape {
      &:before {
        content: "Mobile Landscape";
        background-color: hsl(180, 50%, 50%);
      }
    }

    @include whileTabletPortrait {
      &:before {
        content: "Tablet Portrait";
        background-color: hsl(0, 50%, 50%);
        color: white;
      }
    }
    @include whileTabletLandscape {
      &:before {
        content: "Tablet Landscape";
        background-color: hsl(270, 50%, 50%);
        color: white;
      }
    }

    @include whileDesktopSmall {
      &:before {
        content: "Desktop Small";
        background-color: hsl(45, 50%, 50%);
      }
    }
    @include fromDesktopWide {
      &:before {
        content: "Desktop Wide";
        background-color: hsl(135, 50%, 50%);
      }
    }
  }
}

.revoMainMenu__wrapper--overlay {
  #redesignedSidebarMenu {
    .revoMainMenu__menuItemModule,
    .revoMainMenu__menuItemModule--active {
      .menuAddress.revoMenuItem__text {
        z-index: 1;
        position: relative;
      }
    }
  }
}

#redesignedSidebarMenu {
  .revoMainMenu__menuItemModule {
    .menuAddress.revoMenuItem__text {
      border-width: 0 0 1px 0 !important;
    }
  }

  .menu {
    @include element("separator") {
      height: 24px;
      width: 1px;
      border-right: 1px solid revo-color("typo", "lighter");
    }
    @include element("content") {
      height: 72px;
      background-color: white;
      margin-left: 72px;
    }

    @include element("logo") {
      height: 72px;
      width: 97px;

      > svg {
        margin-left: 25px;
        margin-top: 15px;
      }
    }

    @include element("mainbar") {
      width: 72px;
      position: fixed;
      left: 0;
      top: 0;
      height: 100vh;
      background-color: revo-color("primary", "default");
    }
  }
  ////////////////////////////////////////////////////////////////////
  ///////// MENU STYLES //////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////

  @include block("revoMainMenu") {
    @include element("appTitleWrapper") {
      .revoMenuItem__button {
        button {
          background-color: revo-color("primary", "default");
          height: $css-icon-var-redesign;
        }
      }
    }

    @include element("menuWrap") {
      width: 80px;
      height: 100%;
      display: flex;
      flex-direction: column;
      flex-grow: 0;
      flex-wrap: nowrap;
      flex-basis: auto;
      flex-shrink: 0;
      overflow: hidden;
    }

    @include element("mobileAppTitle") {
      align-items: center;
      color: revo-color("primary", "dark");
      display: flex;
      font-size: 18px;
      font-weight: bold;
      padding: 0 20px;
    }

    @include element("mobileSidebarToggle") {
      button {
        background-color: revo-color("white");
        color: revo-color("primary");
        border: none;
      }

      @include whileTablet {
        border-left: 1px solid revo-color("border");
      }

      @include modifier("open") {
        @include untilTablet {
          button {
            background-color: revo-color("background", "lighter");
          }
        }
        @include whileTablet {
          box-shadow: none;
        }
      }
    }

    @include element("wrapper") {
      display: grid;
      width: $css-icon-var-redesign;
      height: 100%;
      z-index: 10000;

      &.impersonate {
        @include element(("blankSpace", "footerLogoWrap")) {
          background-color: red;
        }
        .revoMenuItem__button,
        button {
          background-color: red;
        }
        .revoMenuItem__button {
          button {
            background-color: red;
          }
        }
      }

      @include whileTabletPortrait {
        @include modifier("expanded") {
          display: initial;
        }

        @include modifier("overlay") {
          display: initial;
        }
      }

      @include fromDesktop {
        @include menuSlideAnimation(width);
      }

      @include modifier("expanded") {
        position: fixed;

        pointer-events: auto;
        transition-duration: 0ms;
        transition-delay: 0ms;

        @include element("wrapAppsList") {
          transition: width 0ms ease-in-out 0ms;
        }
        @include element("backgroundWrapper") {
          transition: width 0ms ease-in-out 0ms;
        }

        @include element("menuWrap") {
          width: auto;
          overflow: initial;
        }

        @include fromTablet {
          position: initial;
        }
      }

      @include modifier("overlay") {
        position: fixed;

        @include element("wrapAppsList") {
          transition: width 0ms ease-in-out 0ms;
        }

        @include element("menuWrap") {
          width: auto;
          overflow: initial;
        }

        @include fromTablet {
          position: initial;
        }
      }

      @include fromDesktop {
        @include modifier("expanded") {
          width: $flyout-width-desktop-redesign;
        }
      }
    }

    @include element("backgroundWrapper") {
      grid-column-start: 1;
      grid-row-start: 1;
      display: grid;

      height: 100%;

      z-index: 0;

      grid-template-columns: $css-icon-var-redesign 1fr;
      position: fixed;
      width: $css-icon-var-redesign;

      .revoMainMenu__wrapper--expanded & {
        //box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.3);
        box-shadow: none;
      }

      .revoMainMenu__wrapper--overlay & {
        //box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.3);
        box-shadow: none;
      }

      .revoMainMenu__wrapper--expanded & {
        display: grid;
        width: 100vw;
        height: 100vh;
      }

      .revoMainMenu__wrapper--overlay & {
        display: grid;
        width: 100vw;
        height: 100vh;
      }

      .revoMainMenu__wrapper--expanded & {
        position: absolute;
      }

      .revoMainMenu__wrapper--overlay & {
        position: absolute;
      }

      @include menuSlideAnimation(width);

      .revoMainMenu__wrapper--expanded & {
        width: $css-flyout-var-redesign;
      }

      .revoMainMenu__wrapper--overlay & {
        width: $css-flyout-var-redesign;
      }
    }

    @include element("menu") {
      padding: 1000px;
      display: flex;
      flex-direction: column;
      grid-column-start: 1;
      grid-row-start: 1;
      height: 100vh;
      position: fixed;
      z-index: 1000;

      ul.popup-menu {
        position: absolute;
        bottom: 3px;
        left: calc(100% + 0px);
        min-width: 138px;
        background: #fff;
        display: none;
        margin: 0;
        list-style: none;
        border-radius: 3px;
        box-shadow: rgb(0 0 0 / 21%) 0 6px 32px 0;
        will-change: transform;
        padding: 8px;

        &.active {
          display: block;
        }

        &:after {
          content: "";
          width: 0;
          height: 0;
          position: absolute;
          bottom: 14px;
          left: -5px;
          border-width: 5px 5px 5px 0;
          border-style: solid;
          border-color: transparent #fff;
        }
        &:before {
          content: "";
          width: 0;
          height: 0;
          position: absolute;
          bottom: 14px;
          left: -7px;
          border-color: transparent rgba(0, 0, 0, 0.05);
          border-style: solid;
          border-width: 5px 5px 5px 0;
        }

        li {
          list-style: none;
          padding: 4px;
          font-size: 12px;
          line-height: 16px;
          color: revo-color("typo", "default");
          margin-bottom: 4px;
          &:hover {
            background-color: revo-color("background", "lighter");
            cursor: pointer;
          }
          a {
            padding: 0 16px;
            border: 0;
            display: block;
            border-radius: 4px;
            text-align: left;
            white-space: nowrap;
            outline: none;
            line-height: 24px;
            background-color: transparent;
            font-size: 14px;
            color: revo-color("primary", "default");

            &.redText {
              color: revo-color("negative", "default");
            }

            &:hover {
              cursor: pointer;
            }
          }
        }
      }

      @include whileTabletPortrait {
        .revoMainMenu__wrapper--expanded & {
          display: initial;
        }

        .revoMainMenu__wrapper--overlay & {
          display: initial;
        }
      }
    }

    @include element("itemsWrapper") {
      scrollbar-width: none;
      flex: 1 1 auto;
      display: block;
      flex-direction: column;

      &::-webkit-scrollbar {
        width: 0;
        background-color: transparent;
      }

      @include element("scrollerHelper") {
        position: fixed;

        width: var(--icon-size-redesign);
        text-align: center;
        z-index: 1000;
        user-select: none;
        transition: width 0s;

        @include modifier("top") {
          top: calc(var(--icon-size-redesign) + 1px);

          @include whileMobile {
            top: var(--icon-size-redesign);
          }

          background: linear-gradient(rgb(90, 85, 235) 60%, rgba(90, 85, 235, 0));
          & i {
            top: -4px;
          }
        }

        @include modifier("bottom") {
          bottom: var(--icon-size-redesign);
          background: linear-gradient(rgba(90, 85, 235, 0), rgb(90, 85, 235) 60%);
          & i {
            top: 4px;
          }
        }

        & i {
          color: revo-color("primary", "light");
          font-weight: bold;
          position: relative;
        }
      }
    }

    @include element("menuToggleWrapper") {
      flex: 0 0 auto;
      display: flex;
      align-items: flex-start;

      .revoMenuItem__text {
        border-bottom: 1px solid revo-color("border");
        font-size: 18px;
        font-weight: bold;
        color: revo-color("primary", "dark");
        height: $css-icon-var-redesign;
      }

      .revoMenuItem__button {
        z-index: 999;
        button {
          border-color: revo-color("primary", "dark");
          height: $css-icon-var-redesign;
          background-color: revo-color("primary", "dark");
        }

        border-bottom: 1px solid revo-color("border");
      }

      @include modifier("mobile") {
        display: flex;
        align-items: stretch;
        flex-direction: column;
        justify-content: flex-start;
      }
    }

    @include element("topBarLogo") {
      display: flex;
      flex-direction: column;
      flex: 0 0 auto;
      justify-content: center;
      margin-right: 12px;
    }

    @include element("wrapAppsList") {
      height: 100%;
      z-index: 10;
    }

    @include element("blankSpace") {
      width: 60px;
      display: block;
      padding: 16px;
      z-index: 999;
      flex: 0 0 auto;
      background-color: revo-color("primary", "default");
    }

    @include element("dropMenu") {
      @include menuSlideAnimation(transform);
      display: block;
      padding: 16px;
      width: 100%;
      overflow: hidden;

      transform: translateX(-100%);

      .revoMainMenu__wrapper--expanded & {
        transform: translateX(0);
        overflow: initial;
      }

      .revoMainMenu__wrapper--overlay & {
        transform: translateX(0);
        overflow: initial;
      }

      > div {
        width: 100%;
      }

      button {
        width: 100%;
        display: flex;
        justify-content: space-between;
      }

      ul {
        width: 100%;

        li.divider {
          cursor: default;
        }

        li {
          padding: 4px !important;
          margin-bottom: 4px;
          height: 33px;

          &:last-child {
            margin-bottom: 0;
          }
          &:hover:not(.divider) {
            background-color: revo-color("background", "lighter");
            button {
              background-color: transparent !important;
            }
          }
        }
      }
    }

    @include element("menuItem") {
      display: inline-flex;
      flex: 0 0 auto;
      width: $css-flyout-var-redesign;
      position: relative;

      @include menuSlideAnimation(width);

      &:hover {
        button {
          background-color: revo-color("primary", "dark");
          border-color: revo-color("primary", "dark");
        }
        .revoMenuItem__text {
          background-color: revo-color("primary", "dark");
          color: revo-color("white");
        }

        @include element("dropMenu") {
          button {
            background-color: revo-color("primary", "default");
          }
          ul {
            li {
              button {
                background-color: initial;
              }
            }
          }
        }
      }

      @include modifier("active") {
        button {
          background-color: revo-color("primary", "light");
          border-color: revo-color("primary", "dark");
        }
        .revoMenuItem__text {
          background-color: revo-color("background", "lighter");
          color: revo-color("white");
          span {
            color: revo-color("white");
          }
        }
      }
    }

    @include element("menuItemModule") {
      display: inline-flex;
      flex: 0 0 auto;
      width: $css-flyout-var-redesign;
      @include menuSlideAnimation(width);

      .revoMenuItem__button {
        background-color: revo-color("white");
      }
      &:hover {
        button {
          background-color: revo-color("primary", "dark");
          color: revo-color("white");
          border-top: 1px solid white;
          border-bottom: 1px solid white;
        }
        .revoMenuItem__text {
          background-color: revo-color("primary", "dark");
          color: revo-color("white");
          border-top: 1px solid white;
          border-bottom: 1px solid white;
        }
        @include element("dropMenu") {
          button {
            background-color: revo-color("primary", "default");
          }
          ul {
            li {
              button {
                background-color: initial;
              }
            }
          }
        }
      }

      @include modifier("active") {
        button {
          background-color: revo-color("primary", "dark");
          border-top: 1px solid white;
          border-bottom: 1px solid white;
          color: revo-color("white");
        }
        .revoMenuItem__text {
          background-color: revo-color("primary", "dark");
          border-top: 1px solid white;
          border-bottom: 1px solid white;
          color: revo-color("white");
        }
        span {
          color: revo-color("white");
        }
      }
    }

    @include element("appTitleWrapper") {
      padding: 16px 8px;
    }
    @include element("footerLogo") {
      background-image: url(https://assets.21real.estate/21_logo_inverted.svg);
      background-size: cover;
      width: 36px;
      height: 15px;
      background-position: 0px -1px;
    }

    @include element("footerServiceWrap") {
      display: flex;
      align-items: center;
      height: 100%;
      &:hover {
        @include element("footerLogoWrap") {
          background-color: revo-color("primary", "dark");
        }
        @include element("footerService") {
          background-color: revo-color("background", "lighter");
        }
      }
    }

    @include element("footerWrapper") {
      display: flex;
      flex-direction: column;
    }

    @include element("footerUserWrap") {
      display: flex;
      align-items: center;
      height: 100%;
      width: 100%;

      @include element("footerLogoWrap") {
        justify-content: center;

        padding: 0 14px;
        i {
          color: revo-color("background", "default");
          cursor: default;
        }
      }

      &:hover {
        @include element("footerLogoWrap") {
          background-color: revo-color("primary", "dark");
        }
        @include element("footerService") {
          background-color: revo-color("background", "lighter");
        }
      }
    }

    @include element("footerLogoWrap") {
      padding: 0 8px;
      height: 60px;
      display: flex;
      align-items: center;
      background-color: revo-color("primary", "default");
      z-index: 50;
    }

    @include element("footerService") {
      @include menuSlideAnimation(transform);

      white-space: nowrap;
      transform: translateX(-100%);
      height: 100%;
      display: flex;
      align-items: center;
      width: 100%;

      > div {
        cursor: default;
      }

      &:after {
        content: " ";
        position: absolute;
        right: -11px;
        width: 10px;
        height: 100%;
        background: transparent;
      }

      .revoMainMenu__wrapper--expanded & {
        transform: translateX(0);
      }

      .revoMainMenu__wrapper--overlay & {
        transform: translateX(0);
      }

      &:hover {
        ul.popup-menu {
          display: block;
        }
      }
    }
  }

  @include block("revoMenuItem") {
    @include element("logo") {
      @include menuSlideAnimation(transform);
      display: flex;
      align-self: center;

      > img {
        height: auto;
        width: 100%;
      }

      @include whileMobile {
        height: 32px;
        width: 96px;
      }

      @include whileTablet {
        height: 40px;
        width: 120px;
      }

      @include fromDesktop {
        height: 48px;
        width: 144px;
      }

      padding: 0 20px;
      white-space: nowrap;
      z-index: -10;

      .revoMainMenu__wrapper--expanded & {
        transform: translateX(0);
      }

      .revoMainMenu__wrapper--overlay & {
        transform: translateX(0);
      }
    }

    @include element("logoTop") {
      width: 80px;
      position: relative;
      left: -10px;
      display: flex;
    }

    @include element("text__container") {
      display: flex;
      width: 100%;
    }

    @include element("text") {
      display: flex;
      align-items: center;

      color: revo-color("primary");

      padding: 0;
      transform: translateX(-100%);
      white-space: nowrap;
      z-index: -10;

      @include untilTablet {
        width: 100%;
      }

      @include fromTablet {
        @include menuSlideAnimations((transform));
        transform: translateX(calc(-1 * #{$css-flyout-var-redesign} - #{$css-icon-var-redesign}));
        width: calc(#{$css-flyout-var-redesign} - #{$css-icon-var-redesign} - 20px);
        &.menuAddress {
          width: calc(#{$css-flyout-var-redesign} - 20px);
          transform: translateX(calc(-1 * #{$css-flyout-var-redesign} - #{$css-icon-var-redesign}));
        }
      }

      .revoMainMenu__wrapper--expanded & {
        transform: translateX(0);
      }

      .revoMainMenu__wrapper--overlay & {
        transform: translateX(0);
      }
    }

    @include element("button") {
      align-self: end;
      z-index: 99;
      pointer-events: auto;

      button {
        border-radius: 0;
        height: 60px;
        width: 60px;
        &:active,
        &:focus {
          outline: none;
        }
      }

      @include whileTabletPortrait {
        button {
          height: $iconbar-width-tablet-redesign;
          width: $iconbar-width-tablet-redesign;
        }
      }

      @include fromTabletLandscape {
        button {
          width: $css-icon-var-redesign;
        }
      }

      @include fromDesktop {
        button {
          width: $css-icon-var-redesign;
        }
      }
    }
  }

  .revoMainMenu__footerUserWrap .revoMainMenu__footerService {
    border-top: 1px solid revo-color("border", "default");
  }

  .revoMainMenu__footerUserWrap .revoMainMenu__footerLogoWrap {
    border-top: 1px solid revo-color("border", "default");
  }

  .revoMainMenu__footerUserWrap .revoMainMenu__footerService li.divider {
    cursor: default;
    background: none;
  }

  @include block("devHelper") {
    @include element("responsiveIndicator") {
      &:before {
        display: block;
        padding: 5px;
        font-size: 12px;
      }

      @include whileMobilePortrait {
        &:before {
          content: "Mobile Portrait";
          background-color: hsl(90, 50%, 50%);
        }
      }
      @include whileMobileLandscape {
        &:before {
          content: "Mobile Landscape";
          background-color: hsl(180, 50%, 50%);
        }
      }

      @include whileTabletPortrait {
        &:before {
          content: "Tablet Portrait";
          background-color: hsl(0, 50%, 50%);
          color: white;
        }
      }
      @include whileTabletLandscape {
        &:before {
          content: "Tablet Landscape";
          background-color: hsl(270, 50%, 50%);
          color: white;
        }
      }

      @include whileDesktopSmall {
        &:before {
          content: "Desktop Small";
          background-color: hsl(45, 50%, 50%);
        }
      }
      @include fromDesktopWide {
        &:before {
          content: "Desktop Wide";
          background-color: hsl(135, 50%, 50%);
        }
      }
    }
  }
}
