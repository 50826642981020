.revo-scrollbar-light {
  scrollbar-color: revo-color("border", "default") transparent;
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    background-color: transparent;
    width: 4px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: revo-color("border", "default");
    border-radius: 2px;
  }
}

.revo-scrollbar-dark {
  scrollbar-color: #ccd0dc88 transparent;
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    background-color: transparent;
    width: 4px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #ccd0dc88;
    border-radius: 2px;
  }
}
