body.print-mode {
  background-color: white;
  padding: 0;
  margin: 0;

  #revo-menu {
    display: block;
    background-color: white;

    .revoAppDrawer {
      display: block;

      .revoMenu__menuWrapper {
        display: block;
        padding: 0;
        margin: 0;
      }

      .revoAppDrawer__pushedArea {
        display: block;
        overflow: inherit;
      }
    }

    .revoMainMenu__wrapper {
      display: none !important;
      padding: 0;
      margin: 0;
    }

    #revo-content {
      display: block;
      padding: 0;
      margin: 0;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 9995;

      > div div.react-grid-layout {
        background: white;
      }

      > div.bp4-focus-disabled > div > div.revo-grid > div.revo-grid {
        background: white;
      }

      .assessment-dashboard-component {
        background: white;
      }
    }

    .revoMenu__contentWrapper {
      display: block;
      padding: 0;
      margin: 0;
    }

    .menu__mainbar {
      display: none;
    }

    .menu__content {
      display: none;
    }

    .dashboard-widget-assessment-dashboard-nearest-accessibility-widget {
      width: auto;
      height: 100%;
    }

    .dashboard-widget-assessment-dashboard-population-distribution-widget {
      width: auto;
    }

    button[type="submit"] {
      display: none;
    }

    .appHeaderMultiDashboardSelect {
      height: 30px;
    }

    .dashboard-widget-assessment-dashboard-comparables-widget .count-label {
      font-size: 18px;
    }
  }
}
