/**
 * MUI Globals
 */

* {
  box-sizing: border-box;
}

// Body reset
html {
  font-size: 10px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body {
  font-family: "Lato", "Roboto", "Arial", "Helvetica", sans-serif;
  font-size: $base-font-size;
  font-weight: normal;
  line-height: $base-line-height;
  color: revo-color("typo");
  background-color: revo-color("background", "light");
  box-sizing: border-box;

  // we cannot control where ligatures are set, which might hurt readability
  // see https://de.wikipedia.org/wiki/Ligatur_(Typografie)#Anwendung_im_Deutschen
  font-variant-ligatures: no-common-ligatures;
}

// Links
a {
  color: revo-color("primary");
  text-decoration: revo-color("primary", "light");
  cursor: pointer;
  &:hover,
  &:focus {
    text-decoration: underline;
  }

  &:focus {
    @include mui-tab-focus();
  }
}

// lists
ul,
ol {
  margin-top: 0;
  margin-bottom: $space-lg;
}

// Horizontal rules
hr {
  margin: 0;
  border: 0;
  height: 1px;
  background-color: revo-color("border");
}

// Strong
strong {
  font-weight: 700;
}

// Abbreviations
abbr[title] {
  cursor: help;
  border-bottom: 1px dotted revo-color("primary");
}

// font-smoothing
html,
body,
button,  /* safari issue*/
input,  /* safari issue*/
textarea {
  // safari issue
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
}
