///
/// MOBILE BREAKPOINTS
///
@mixin whileMobilePortrait {
  @media (max-width: 479.99px) {
    @content;
  }
}

@mixin whileMobileLandscape {
  @media (min-width: 480px) and (max-width: 767.99px) {
    @content;
  }
}

@mixin whileMobile {
  @media (max-width: 767.99px) {
    @content;
  }
}

///
/// TABLET BREAKPOINTS
///
@mixin untilTablet {
  @include whileMobile {
    @content;
  }
}

@mixin untilTabletLandscape {
  @media (max-width: 1023.99px) {
    @content;
  }
}

@mixin fromTablet {
  @media (min-width: 768px) {
    @content;
  }
}

@mixin fromTabletLandscape {
  @media (min-width: 1024px) {
    @content;
  }
}

@mixin whileTablet {
  @media (min-width: 768px) and (max-width: 1368.99px) {
    @content;
  }
}

@mixin whileTabletPortrait {
  @media (min-width: 768px) and (max-width: 1023.99px) {
    @content;
  }
}

@mixin whileTabletLandscape {
  @media (min-width: 1024px) and (max-width: 1368.99px) {
    @content;
  }
}

///
/// DESKTOP BREAKPOINTS
///
@mixin fromDesktop {
  @include fromDesktopSmall {
    @content;
  }
}

@mixin untilDesktop {
  @include untilDesktopSmall {
    @content;
  }
}

@mixin untilDesktopSmall {
  @media (max-width: 1368.99px) {
    @content;
  }
}

@mixin fromDesktopSmall {
  @media (min-width: 1369px) {
    @content;
  }
}

@mixin whileDesktopSmall {
  @media (min-width: 1369px) and (max-width: 1679.99px) {
    @content;
  }
}

@mixin untilDesktopWide {
  @media (max-width: 1368.99px) {
    @content;
  }
}

@mixin fromDesktopWide {
  @media (min-width: 1680px) {
    @content;
  }
}
