@each $color, $subColors in $revo-colors {
  @if type-of($subColors) == "map" {
    @each $subcolor, $value in $subColors {
      .re-bg--#{$color}--#{$subcolor} {
        background-color: revo-color($color, $subcolor);
      }
    }
  } @else {
    .re-bg--#{$color} {
      background-color: revo-color($color);
    }
  }
}
