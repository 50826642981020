@use "sass:map";
@import "~@21re/re-style-core";
@import "./styles/21st/core/_core.scss";
@import "~sass-bem";
@import "./styles/mui.scss";
@import "./styles/dashboard.scss";
@import "./styles/print.scss";

// hack to avoid controls stacked on top of each other
.ol-rotate {
  top: 3em;
}

.force-macos-scrollbar-visibility::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

.force-macos-scrollbar-visibility::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

/* CSS from https://gist.github.com/IceCreamYou/cd517596e5847a88e2bb0a091da43fb4 */
.force-show-scrollbars ::-webkit-scrollbar-track:vertical {
  border-left: 1px solid #e7e7e7;
  box-shadow: 1px 0 1px 0 #f6f6f6 inset, -1px 0 1px 0 #f6f6f6 inset;
}

.force-show-scrollbars ::-webkit-scrollbar-track:horizontal {
  border-top: 1px solid #e7e7e7;
  box-shadow: 0 1px 1px 0 #f6f6f6 inset, 0 -1px 1px 0 #f6f6f6 inset;
}

.force-show-scrollbars ::-webkit-scrollbar {
  -webkit-appearance: none;
  background-color: #fafafa;
  width: 16px;
}

.force-show-scrollbars ::-webkit-scrollbar-thumb {
  background-clip: padding-box;
  background-color: #c1c1c1;
  border-color: transparent;
  border-radius: 9px 8px 8px 9px;
  border-style: solid;
  border-width: 3px 3px 3px 4px; /* Workaround because margins aren't supported */
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

/* Unfortunately scrollbars can't use CSS transitions. Also, it's not possible
to highlight the thumb when the scrollbar track is hovered without some
JavaScript acrobatics; https://jsfiddle.net/QcqBM/6/ is a start, but you
also have to check whether the element has a scrollbar and if so how wide
it is. */
.force-show-scrollbars ::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.5);
}

body > canvas {
  display: none;
}

.bp4-popover-wrapper.fullWidth,
.bp4-popover-wrapper.fullWidth > .bp4-popover-target {
  display: block;
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

#revo-content .bp4-button .bp4-icon {
  color: map.get($primary, "default");
}

.numeric-score-input-panel {
  .close-button button i {
    margin: 0;
  }

  .close-button button {
    padding-right: 0;
  }

  .bp4-control-group,
  .bp4-input-group {
    flex-grow: 1;
    flex-shrink: 1;
  }

  .numericScoreSum {
    .bp4-button-group {
      visibility: hidden;
    }

    .bp4-input[readonly] {
      border: none;
      box-shadow: none;
    }

    .invalidNumericInputScoreWeights.bp4-control-group {
      border: 1px solid map.get($negative, "dark");
      border-radius: 3px;
      background-color: map.get($negative, "light");
    }
  }

  .validNumericScoreSum.bp4-control-group {
    border: 1px solid map.get($positive, "dark");
    background-color: map.get($positive, "light");
    border-radius: 3px;
  }

  .validNumericScoreSum.bp4-control-group input {
    background-color: map.get($positive, "light");
  }

  .validNumericInputScoreWeights.bp4-control-group {
    border: 1px solid map.get($border, "default");
    border-radius: 3px;
  }

  .bp4-input-group input:focus {
    outline: none;
  }

  .invalidNumericInputScoreWeights.bp4-control-group {
    border: 1px solid map.get($negative, "dark");
    border-radius: 3px;
  }

  .validNumericInputScoreWeights > * > *,
  .validNumericInputScoreWeights > * > .bp4-button:not([class*="bp4-intent-"]) {
    background-color: white;
    background-image: none;
    box-shadow: none;
    user-select: none;
    outline: none;
  }

  .invalidNumericInputScoreWeights > * > .bp4-button:not([class*="bp4-intent-"]) {
    background-color: map.get($negative, "light");
    color: map.get($negative, "dark");
    background-image: none;
    box-shadow: none;
    user-select: none;
    outline: none;
  }

  .invalidNumericInputScoreWeights * {
    background-color: map.get($negative, "light");
    color: map.get($negative, "dark");
    box-shadow: none;
  }
}

#deleteAssessmentObjectDropdownButton div ul li button {
  color: map.get($negative, "default");
}

#marketDataComparisonAreaSettingsButtons {
  min-width: 32px;
  height: 32px;
  font-size: 14px;

  i {
    font-size: 16px;
  }
}

.disableSecondButtonInGroup > div > div > div > div:nth-child(2) {
  pointer-events: none;
  background-color: rgba(206, 217, 224, 0.5);
}

#quickStartAssessmentButton {
  width: 300px;
}

.multiDashboardSelect {
  .bp4-popover-target {
    width: 100%;
  }

  .bp4-button {
    background: white;
    border-radius: 4px;
    width: 100%;
    justify-content: space-between;
  }
}

.bp4-portal {
  .dashboard-select-item-dropdown-wrapper {
    > div {
      > div {
        > i {
          color: var(--revo-color-primary-default);
          text-decoration: none;
        }

        > i:hover {
          color: var(--revo-color-primary-dark);
        }
      }
    }
  }
}

.customSelectPopover > div > div > ul {
  max-width: 100% !important;
  max-height: 500px !important;
  font-size: 14px;
  display: flex;
  flex-direction: column-reverse;

  li:first-child {
    position: absolute;
    width: 100%;
    left: -0.1px;
    bottom: -42px;
    box-shadow: 0 0 0 0 rgba(17, 20, 24, 0.1), 0 2px 4px rgba(17, 20, 24, 0.2), 0 8px 24px rgba(17, 20, 24, 0.2);
  }

  a {
    text-decoration: none;

    &:focus {
      outline: none;
    }
  }

  a:has(.MultiSelectAddMenuItem) {
    background-color: white !important;

    &:hover {
      background-color: white !important;
    }
  }
}

.customSelectPopoverError > div > div > ul {
  li:first-child {
    bottom: -62px !important;
  }
}

.districtDataHelp .moveable-control-box {
  display: none;
  visibility: hidden;
}

#comparablesMultiSliderHandleLabel {
  .bp4-slider-handle.bp4-start .bp4-slider-label {
    text-align: end;
    margin-left: -8px;
    background: none;
    border: none;
    box-shadow: none;
    color: #4b4b5a;
  }

  .bp4-slider-handle.bp4-end .bp4-slider-label {
    text-align: left;
    margin-left: 14px;
    background: none;
    border: none;
    box-shadow: none;
    color: #4b4b5a;
  }
}

#assessmentDownloadDropdown > div > div > div > button {
  i:first-child {
    font-size: 34px;
  }

  i:last-child {
    display: none;
  }
}

#dashboardDownloadDropdown > div > div {
  &:after {
    margin-left: -40px;
  }

  div > button {
    i:first-child {
      font-size: 34px;
      margin: 0;
    }

    i:last-child {
      display: none;
    }
  }
}

#lanaHeaderToolsDropdown {
  div {
    height: 100%;
  }

  > div > div {
    > button {
      height: 100%;

      i:last-child {
        display: none;
      }
    }
  }
}

#assessmentEntryNotes {
  position: absolute;
  right: 120px;
  top: 76px;
}

.private-pois-categories-panel-stack2 {
  height: 100%;
}

.comparables-transaction-source-info div > p {
  font-size: 14px;
}

.comparables-transaction-source-info {
  padding: 0;
  border-bottom: none;
}

.comparablesMultiSliderQuarterAvailableDiv {
  padding: 24px 16px 0 16px;

  .bp4-slider-handle {
    span.bp4-slider-label {
      display: none;
    }
  }

  .bp4-slider-axis {
    .bp4-slider-label {
      margin-top: -36px;
    }
  }

  .quartersLabel {
    text-align: right;
    margin: -24px -8px 0 0;
  }
}

#revo-menu .revoAppDrawer .revoMenu__contentWrapper:has(div.with-body-scroll) {
  height: auto;
  overflow-y: auto;
}

#revo-menu .revoAppDrawer .revoMenu__contentWrapper div.with-body-scroll {
  padding: 15px;
}

.logged-out-background {
  background-size: cover;
  background-position: 50% bottom;
  background-repeat: no-repeat;
  background-image: url("/public/assets/logged-out-background.png");
  height: 100vh;

  .revoAppDrawer__pushedArea {
    background: none;
  }
}

body.print-mode.logged-out-background {
  background-image: none;
}

#loadingDimmerLoginPage {
  min-width: 400px;
  width: 400px;
}

.re-login-container > div {
  border-radius: 3px;
}

div.generated-content {
  > p:not(:last-child) {
    margin-bottom: 8px;
  }

  > p:last-child {
    margin-bottom: 0;
  }
}

.bp4-card {
  a:hover {
    color: white;
  }
}

.react-grid-layout {
  position: relative;
  transition: height 200ms ease;
}

.react-grid-item {
  transition: all 200ms ease;
  transition-property: left, top;
}

.react-grid-item img {
  pointer-events: none;
  user-select: none;
}

.react-grid-item.cssTransforms {
  transition-property: transform;
}

.react-grid-item.resizing {
  z-index: 1;
  will-change: width, height;
}

.react-grid-item.react-draggable-dragging {
  transition: none;
  z-index: 3;
  will-change: transform;
}

.react-grid-item.dropping {
  visibility: hidden;
}

.react-grid-item.react-grid-placeholder {
  background: #dcdcf5;
  opacity: 0.2;
  transition-duration: 100ms;
  z-index: 2;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.react-grid-item:has(.add-section) > .react-resizable-handle {
  display: none !important;
}

.react-grid-item > .react-resizable-handle {
  position: absolute;
  width: 20px;
  height: 20px;
}

.react-grid-item > .react-resizable-handle::after {
  content: "";
  position: absolute;
  right: 3px;
  bottom: 3px;
  width: 5px;
  height: 5px;
  border-right: 2px solid rgba(0, 0, 0, 0.4);
  border-bottom: 2px solid rgba(0, 0, 0, 0.4);
}

.react-resizable-hide > .react-resizable-handle {
  display: none;
}

.react-grid-item > .react-resizable-handle.react-resizable-handle-sw {
  bottom: 0;
  left: 0;
  cursor: sw-resize;
  transform: rotate(90deg);
}

.react-grid-item > .react-resizable-handle.react-resizable-handle-se {
  bottom: 0;
  right: 0;
  cursor: se-resize;
}

.react-grid-item > .react-resizable-handle.react-resizable-handle-nw {
  top: 0;
  left: 0;
  cursor: nw-resize;
  transform: rotate(180deg);
}

.react-grid-item > .react-resizable-handle.react-resizable-handle-ne {
  top: 0;
  right: 0;
  cursor: ne-resize;
  transform: rotate(270deg);
}

.react-grid-item > .react-resizable-handle.react-resizable-handle-w,
.react-grid-item > .react-resizable-handle.react-resizable-handle-e {
  top: 50%;
  margin-top: -10px;
  cursor: ew-resize;
}

.react-grid-item > .react-resizable-handle.react-resizable-handle-w {
  left: 0;
  transform: rotate(135deg);
}

.react-grid-item > .react-resizable-handle.react-resizable-handle-e {
  right: 0;
  transform: rotate(315deg);
}

.react-grid-item > .react-resizable-handle.react-resizable-handle-n,
.react-grid-item > .react-resizable-handle.react-resizable-handle-s {
  left: 50%;
  margin-left: -10px;
  cursor: ns-resize;
}

.react-grid-item > .react-resizable-handle.react-resizable-handle-n {
  top: 0;
  transform: rotate(225deg);
}

.react-grid-item > .react-resizable-handle.react-resizable-handle-s {
  bottom: 0;
  transform: rotate(45deg);
}

.rating-manager-map {
  div.rating-manager-map-search-meta-popup-content {
    border-radius: 5px;

    div > label {
      font-size: 16px;
    }
  }
}

.assessment-dashboard-component {
  font-size: 14px;
  height: 100%;
  background-color: var(--revo-color-background-lighter);
}

.ol-control button {
  font-size: 1.14em;
  background-color: rgba(0, 60, 136, 0.5);
  color: white;
}
#view-mode-selection-dropdown {
  width: 100%;
  > div {
    width: 100%;
    > div button {
      padding: 9px 9px 9px 16px;
      width: 100%;
      display: flex;
      i:last-child {
        margin-left: auto;
      }
    }
    > ul {
      width: 100%;
    }
  }
}
