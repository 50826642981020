@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 0.2;
  }
}

@keyframes fadeOut {
  from {
    opacity: 0.2;
  }

  to {
    opacity: 0;
  }
}

@include block("revoAppDrawer") {
  white-space: nowrap;
  display: flex;

  @include modifier("open") {
    display: flex;
  }

  @include element("pushedArea") {
    position: static;
    white-space: initial;
    display: inline-block;
    min-height: 100vh;
    z-index: 100;
    width: 100vw;
    background-color: revo-color("background");

    @include whileMobile {
      width: 100%;
    }
  }

  @include element("closeButton") {
    @include fromTablet {
      display: none;
    }
  }

  @include element("menuFloater") {
    height: 100vh;
    display: flex;
    flex-direction: column;
    background-color: revo-color("background", "dark");
    width: $flyout-width-desktop;

    @include whileMobile {
      height: var(--windowInnerHeight);
    }
    .revoAppDrawer--open & {
      @include untilTablet {
        width: 100%;
      }
    }
  }
  @include element("logo") {
    background-image: url(https://assets.21real.estate/21_logo_inverted.svg);
    background-size: cover;
    height: 24px;
    width: 55px;
    min-height: 24px;
    min-width: 55px;
    max-height: 24px;
    max-width: 55px;
  }

  @include element("menuWrapper") {
    background-color: revo-color("background", "dark");
    border-right: 1px solid revo-color("white");
    display: inline-flex;
    flex-direction: column;
    width: 320px;
    position: fixed;
    white-space: initial;
    z-index: 100;
    height: 100vh;

    @include whileMobile {
      position: absolute;
      width: 100vw;
      height: var(--windowInnerHeight);
      display: none;

      @include at("open") {
        display: flex;
        @include whileMobile {
          z-index: 15000;
        }
      }
    }

    @include at("open") {
      flex: 0 0 auto;
    }
  }

  @include element("appList") {
    flex: 1 1 auto;
    overflow-y: auto;
    overflow-x: hidden;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    a:hover {
      text-decoration: none;
    }

    & > .re-grid {
      flex: 0 0 auto;
    }
  }

  @include element("wrapper") {
    @include modifier("open") {
      overflow-x: hidden;
    }
  }
}

// Animation classes
@include fromTablet {
  @include block("revoAppDrawer") {
    .revoAppDrawer__pushedArea {
      transform: translateX(0px);
      transition: transform 0.3s ease-in-out;
      overflow: hidden;

      &::after {
        content: " ";
        height: 100%;
        width: 100%;

        background-color: revo-color("black");
        opacity: 0;

        display: none;

        position: absolute;
        top: 0;
        z-index: 15000;

        transition: opacity 0.3s ease-in-out;
      }
    }

    @include modifier("animate-enter") {
      .revoAppDrawer__pushedArea {
        transform: translateX(320px);

        &::after {
          display: block;
        }
      }
    }

    @include modifier("animate-enter-active") {
      .revoAppDrawer__pushedArea {
        &::after {
          display: block;
          opacity: 0.3;
        }
      }
    }

    @include modifier("animate-enter-done") {
      .revoAppDrawer__pushedArea {
        transform: translateX(320px);

        &::after {
          display: block;
          opacity: 0.3;
        }
      }
    }

    @include modifier("animate-exit") {
      .revoAppDrawer__pushedArea {
        transform: translateX(0px);

        &::after {
          display: block;
          opacity: 0;
        }
      }
    }
  }
}

@include block("scrollLock") {
  @include modifier("enabled") {
    height: 100vh;
    overflow: hidden;
    position: absolute;
    width: 100%;

    @include untilTablet {
      .revoAppDrawer__pushedArea {
        height: 0px;
      }
    }
  }
}

// Do not display menu item text or logo, when main menu is collapsed
// and app drawer is expanded. Because of stacking contexts, menu items
// will always have higher z-indexes as the app drawer and will glitch
// into the app-drawer. This prevails this.

.revoAppDrawer--animate-enter,
.revoAppDrawer--animate-exit,
.revoAppDrawer--open {
  // If animation is in progress or the drawer is open AND
  .revoMainMenu__wrapper:not(.revoMainMenu__wrapper--expanded) {
    // the main menu is collapsed
    .revoMenuItem__text,
    .revoMenuItem__logo {
      display: none;
    }
  }
}
