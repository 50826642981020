/**
 * MUI Helpers module
 */

// ============================================================================
// SCROLL LOCK
// ============================================================================

.mui-scrlock--showbar-y {
  overflow-y: scroll !important;
}

.mui-scrlock--showbar-x {
  overflow-x: scroll !important;
}
