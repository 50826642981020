/**
 * MUI Overlay module
 */

#mui-overlay {
  animation: re-mui-overlay-fade-in 0.5s;
  @keyframes re-mui-overlay-fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  background-color: rgba(0, 0, 0, 0.1);
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  overflow: auto;
  top: 0;
  right: 0;
  left: 0;
  z-index: 9999100;
}

.ousideClickArea {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 9999110;
}

.overlayContent {
  z-index: 9999999;
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  pointer-events: none;

  > * {
    pointer-events: initial;
  }
}
